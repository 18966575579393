import React from 'react'
import { Navbar } from './components'
import { Header, Banner, Banner2, AboutUs, Contact, Footer } from './container'


import './App.css'
import './index.css'

const App = () => {
  return (
    <div>
      <Navbar/>
      <Banner2/>
      <Header/>
      <AboutUs/>
      <Contact/>
      <Banner/>
      <Footer/>
    </div>
  )
}

export default App;


