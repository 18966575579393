import React from 'react'
import { images } from '../../constants'

const SubHeadig = ({title}) => {
  return (
    <div style={{ marginBottom: '1rem' }}>
      <p className="p__cormorant">{title}</p>
    </div>
  )
}

export default SubHeadig
