import React from 'react'

import { images } from '../../constants';
import  './Banner.css'

const Banner = () => {
  return (
    <div className='app__banner'>
      <img src={images.log3} alt="logo" className='app__banner_img' style={{ alignSelf: 'center' }}/>
    </div>
  )
}

export default Banner
