import React from 'react'

import { images } from '../../constants';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div className="app__aboutus app__bg flex__center section__padding" id="about">
      
      <div className="app__aboutus-content flex__center">
        <div className="app__aboutus-content_about">
            <img src={images.chef} alt="about_chef" className='app__wrapper_img'/>
        </div>

        <div className="app__aboutus-content_knife flex__center">
        {/* <img src={images.vert} alt="about_knife" /> */}
        </div>

      <div className="app__aboutus-content_history">
        <h1 className="headtext__cormorant">About Us</h1>
        <p className="p__opensans2">Ambrosio has been providing Ontario profitable and high-quality food products to restaurants,  hotels, grocery stores, and businesses alike. With decades of experience under our belt, our food service company has built a reputation for reliability, efficiency, and exceptional customer service, making us the go-to choice for all your food needs.</p>
        <p className="p__opensans2">&#8226;</p>
        <p className="p__opensans2">Whether you're a restaurant looking for top-quality ingredients, or grocer in need of a convenient and reliable food supplier, our food distribution company has the expertise, experience, and passion to meet your every need. </p>
        <p className="p__opensans2">&#8226;</p>
        <p className="p__opensans2">At Ambrosio we believe that food is more than just sustenance; it's a celebration of life, family, and community. Join us today and experience the best of what Ontario has to offer, one delicious bite at a time.</p>
      </div>
    </div> 
    </div>
  )
}

export default AboutUs
