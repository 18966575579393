import React from 'react'
import { images } from '../../constants';
import { SubHeadig } from '../../components'

const Contact = () => {
  return (
    <div className="app__bg2 app__wrapper section__padding" id="contact">
    <div className="app__wrapper_info">
            <SubHeadig title="Let's get in touch"/>
            <h1 className="headtext__cormorant" >Contact</h1>
            <div className="app__wrapper-content">
                <p className="p__cormorant" style={{ color: '#DCCA87', margin: '2rem 0' }}>Peter Asser, President.</p>
                <p className="p__opensans">Email: passer1@gmail.com</p>
                <p className="p__opensans">Telephone: (647) 988-7961</p>
                <p className="p__cormorant" style={{ color: '#DCCA87', margin: '2rem 0' }}>Bridget Asser, VP.</p>
                <p className="p__opensans">Email: bridgetasser@gmail.com</p>
            </div>
        </div>

        <div className="app__wrapper_img">
            <img src={images.chef4} alt="contactChef_img" />
        </div>
        
    </div>
  )
}

export default Contact
