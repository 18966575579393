import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <div className='footer__section'>
      <h2 className='footer__text'>Copyright © 2023, Ambrosio Marketing Inc.</h2>
    </div>
  )
}

export default Footer
