import React from 'react'

import { SubHeadig } from '../../components'
import { images } from '../../constants'
import './Header.css'


const Header = () => (
    <div className="app__header app__wrapper section__padding" id="home">
      <div className="app__wrapper_info">
        <SubHeadig title="Ambrosio" />
        <h1 className="app__header-h1">The Key To Food Service Success</h1>
        <p className="p__opensans" style={{ margin: '2rem 0' }}>With more than 40 years of experience under our belt, Ambrosio has built a reputation for reliability, efficiency, and exceptional customer service, making us the go-to choice for all your food service needs. </p>
      </div>
    
      <div className="app__wrapper_img">
      <img src={images.tables} alt="header_img" />
    </div>

    </div>
  );

export default Header
