import React from 'react'
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineRestaurantMenu} from 'react-icons/md'
import './Navbar.css'
import images from '../../constants/images'


const Navbar = () => {

  const [toggleMenu, setToggleMenu] = React.useState(false)
  return (
    <nav className='app__navbar'>
      <div className='app__navbar-logo'>
        <img src={images.log4} alt="app logo" /> 
      </div>
      <ul className="app__navbar-links">
        <li className="p__opensans"><a href="#home">Home</a></li>
        <li className="p__opensans"><a href="#about">About</a></li>
        <li className="p__opensans"><a href="#contact">Contact</a></li>
      </ul>
      <div className='app__navbar-smallscreen'>
        <GiHamburgerMenu color='#fff' fontSize={27} onClick={() => setToggleMenu(true)}/>
        {toggleMenu && (
              <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
              <ul className="app__navbar-smallscreen-links">
              <MdOutlineRestaurantMenu fontSize={27} className="overlay__close" onClick={() => setToggleMenu(false)}/>
                <li className="p__opensans"><a href="#home">Home</a></li>
                <li className="p__opensans"><a href="#about">About</a></li>
                <li className="p__opensans"><a href="#contact">Contact</a></li>
            </ul>
            </div>
        )}
      </div>
    </nav>
  )
}

export default Navbar
