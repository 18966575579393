import logo from '../assets/logo.png';
import logoWhite from '../assets/logoWhite.png'
import chef2 from '../assets/chef2.png'
import chef from '../assets/chef.jpg'
import tables from '../assets/tables.jpg'
import knife from '../assets/knife.png'
import chef3 from '../assets/chef3.jpg'
import log3 from '../assets/log3B.svg'
import log4 from '../assets/log4w.svg'
import chef4 from '../assets/chef4.jpg'


// eslint-disable-next-line import/no-anonymous-default-export
export default {
    logo,
    logoWhite,
    chef2,
    chef,
    tables,
    knife,
    chef3,
    log3,
    log4,
    chef4
} ;